import { render, staticRenderFns } from "./receipt-from-parties.vue?vue&type=template&id=4af3f991&"
import script from "./receipt-from-parties.vue?vue&type=script&lang=ts&"
export * from "./receipt-from-parties.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports